import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import {
  Currency,
  PaymentFormType,
  PaymentLocation,
  PaymentProvider,
} from "@/autoGeneratedGlobalTypes";
import {
  getExchangeRate as getExchangeRateType,
  getExchangeRateVariables,
} from "@/components/appointment/graphql/__generated__/getExchangeRate";
import { GET_EXCHANGE_RATE } from "@/components/appointment/graphql/GET_EXCHANGE_RATE";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import CashbackDetailsCard from "@/components/common/cashbackDetailsCard";
import { Dropdown, DropdownSizeEnum, DropdownValue } from "@/components/common/dropdown";
import ExchangeRate from "@/components/common/exchangeRate";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import PaymentAmountPicker from "@/components/common/PaymentAmountPicker";
import PaymentCashback from "@/components/common/paymentCashback";
import { MIN_PAYMENT_AMOUNT, paymentTexts } from "@/components/constants";
import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { CLOUD_PAYMENT_CARD_VALUE, CLOUD_PAYMENT_FOREIGN_CARD_VALUE, MAX_DIGITS_AMOUNT, PAYMENT_INITIAL_AMOUNT, ROBOKASSA_PAYMENT_CARD_VALUE, ROBOKASSA_SBP_PAYMENT_CARD_VALUE, SOFTLINE_PAYMENT_CARD_VALUE, SOM_PAYMENT_CARD_VALUE } from "@/constants";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { usePaymentForm } from "@/hooks/usePaymentForm";
import { setUnfinishedPaymentIdFromBankRedirect } from "@/hooks/usePaymentForm/utils";
import { PaymentSuccessDataType, usePayWithSavedCard } from "@/hooks/usePayWithSavedCard";
import { useMarketingHook } from "@/marketing/marketingHook";
import { getDeviceType } from "@/utils/env";
import { currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";
import { cleanupFromTags } from "@/utils/stringUtils";

import "./styles.scss";
import {
  calculateCashback,
  calculateCashbackVariables,
} from "./graphql/__generated__/calculateCashback";
import {
  getPaymentFormData,
  getPaymentFormDataVariables,
} from "./graphql/__generated__/getPaymentFormData";
import { getPaymentPageData } from "./graphql/__generated__/getPaymentPageData";
import { CALCULATE_CASHBACK } from "./graphql/CALCULATE_CASHBACK";
import { GET_PAYMENT_FORM_DATA } from "./graphql/GET_PAYMENT_FORM_DATA";
import { GET_PAYMENT_PAGE_DATA } from "./graphql/GET_PAYMENT_PAGE_DATA";
import { cardToJsx, getFixedPrice, getSuccessUrl, isAmountValid } from "./utils";

const PaymentForm = () => {
  const {
    setUnfinishedPaymentId,
    setPaymentFinishedCallback,
    setSavedCardPaymentResultPath,
    savedCardPaymentResultPath,
    userID,
    isUserLoggedIn,
  } = useContext<UserContextType>(UserContext);
  const { marketingStepsForFixPaymentFromLandingPage } = useMarketingHook();

  const { data, error, loading } = useQuery<getPaymentPageData>(GET_PAYMENT_PAGE_DATA);
  const [calculateCashbackQuery, { data: calculateCashbackData }] = useLazyQuery<
    calculateCashback,
    calculateCashbackVariables
  >(CALCULATE_CASHBACK, { fetchPolicy: "no-cache" });
  const { data: dataFormButtons } = useQuery<getPaymentFormData, getPaymentFormDataVariables>(
    GET_PAYMENT_FORM_DATA,
    {
      variables: {
        input: {
          formType: PaymentFormType.profile,
        },
      },
    },
  );

  const [getExchangeRateQuery, { data: dataExchangeRate }] = useLazyQuery<
    getExchangeRateType,
    getExchangeRateVariables
  >(GET_EXCHANGE_RATE);
  const [isAmountTouched, setIsAmountTouched] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<number>(PAYMENT_INITIAL_AMOUNT);
  const [cards, setCards] = useState<DropdownValue[]>([]);
  const [selectedCard, setSelectedCard] = useState<DropdownValue>(CLOUD_PAYMENT_CARD_VALUE);
  const [rememberCard, setRememberCard] = useState<boolean>(true);
  const [showCashBackDetailsCard, setShowCashBackDetailsCard] = useState<boolean>(false);
  const redirectPathAfterPayment = useLocation().href;// todo: maybe change to profile/result here and in appointment
  const [loadingForForeignPayment, setLoadingForForeignPayment] = useState(false);

  const isSelectedSomPayment = selectedCard.value === SOM_PAYMENT_CARD_VALUE.value;
  const isSelectedSoftlinePayment = selectedCard.value === SOFTLINE_PAYMENT_CARD_VALUE.value;
  const isSelectedCloudPayemnt = selectedCard.value === CLOUD_PAYMENT_CARD_VALUE.value;
  const isSelectedCloudForeignPayemnt = selectedCard.value === CLOUD_PAYMENT_FOREIGN_CARD_VALUE.value;
  const isSelectedRobokassaPayemnt = selectedCard.value === ROBOKASSA_PAYMENT_CARD_VALUE.value;
  const isSelectedSBPPayemnt = selectedCard.value === ROBOKASSA_SBP_PAYMENT_CARD_VALUE.value;

  useEffect(() => {
    if (selectedAmount) {
      calculateCashbackQuery({ variables: { input: { paymentAmount: selectedAmount } } });
    }
  }, [calculateCashbackQuery, selectedAmount]);

  useEffect(() => {
    if (dataFormButtons) {
      setSelectedAmount(dataFormButtons.getPaymentFormData.recommendedSumm);
    }
  }, [dataFormButtons]);

  const fixPaymentHandlerForMarketing = useCallback(() => {
    const fixedPrice = getFixedPrice();
    if (!isUserLoggedIn || !userID) {
      return;
    }

    if (fixedPrice && isUserLoggedIn) {
      marketingStepsForFixPaymentFromLandingPage({ userId: userID?.toString(), fixPaymentAmount: fixedPrice, buttonText: "Страница фикс оплаты" });
    }
  }, [isUserLoggedIn, marketingStepsForFixPaymentFromLandingPage, userID]);

  const paymentSuccessCallback = useCallback(() => {
    const successUrl = getSuccessUrl();

    if (successUrl) {
      fixPaymentHandlerForMarketing();
      navigate(successUrl);
    } else {
      navigate(`/profile/balance`, {
        state: { showPaymentAnimation: true, isSuccessPayment: true },
      });
    }
  }, []);

  const paymentFailCallback = useCallback(() => {
    const successUrl = getSuccessUrl();
    navigate(
      `/profile/payment${successUrl ? `/?successUrl=${successUrl}` : ``}`,
      { state: { isSuccessPayment: false } },
    );
  }, []);

  const toggleShowCashBackDetailsCard = () => {
    const deviceType = getDeviceType();

    if (deviceType === "isMobile") {
      setShowCashBackDetailsCard((prevState) =>
        !prevState);
    } else {
      setAuthParamToURL(window?.location, FormTypeEnum.CashBackInformation);
    }
  };

  const hasCashBackButtons = useMemo(() =>
    dataFormButtons?.getPaymentFormData.buttons?.some((item) =>
      item.cashback?.amount), [dataFormButtons?.getPaymentFormData.buttons]);

  useEffect(() => {
    if (redirectPathAfterPayment.includes("request=success")) {
      const successUrl = getSuccessUrl();
      if (successUrl) {
        setUnfinishedPaymentId(setUnfinishedPaymentIdFromBankRedirect(redirectPathAfterPayment));
        setPaymentFinishedCallback(() =>
          navigate(successUrl));
      } else {
        setUnfinishedPaymentId(setUnfinishedPaymentIdFromBankRedirect(redirectPathAfterPayment));
        navigate(`/profile/balance`, {
          state: { showPaymentAnimation: true },
        });
      }
    }

    if (redirectPathAfterPayment.includes("request=fail")) {
      setUnfinishedPaymentId(setUnfinishedPaymentIdFromBankRedirect(redirectPathAfterPayment));
      const successUrl = getSuccessUrl();
      navigate(`/profile/payment${successUrl ? `/?successUrl=${successUrl}` : ``}`);
    }
  }, [redirectPathAfterPayment, setPaymentFinishedCallback, setUnfinishedPaymentId]);

  const { openPaymentForm, isPriceFixed } = usePaymentForm({
    onSuccess: paymentSuccessCallback,
    onFail: paymentFailCallback,
  });

  const paymentWithSavedCardSuccessCallback = useCallback(
    (dataSuccess: PaymentSuccessDataType) => {
      const successUrl = getSuccessUrl();
      fixPaymentHandlerForMarketing();

      setSavedCardPaymentResultPath(
        `/profile/payment-result/?success=1&payment_id=${
          dataSuccess.makePaymentWithSavedCard.id
        }${successUrl ? `&successUrl=${successUrl}` : ``}`,
      );
    },
    [setSavedCardPaymentResultPath],
  );

  const paymentWithSavedCardFailCallback = useCallback(
    (dataFail: PaymentSuccessDataType) => {
      const successUrl = getSuccessUrl();
      setSavedCardPaymentResultPath(
        `/profile/payment-result/?failure=1&payment_id=${
          dataFail.makePaymentWithSavedCard.id
        }${
          successUrl ? `&successUrl=${successUrl}` : ``
        }`,
      );
    },
    [setSavedCardPaymentResultPath],
  );

  const { payWithSavedCard, loading: payWithSavedCardLoading } = usePayWithSavedCard({
    onSuccess: paymentWithSavedCardSuccessCallback,
    onFail: paymentWithSavedCardFailCallback,
  });

  useEffect(() => {
    if (data && !error && !loading) {
      const joinSoftLinePaymentFlag = process.env.GATSBY_ADD_SOFTLINE_PAYMENT === "true";
      const joinSomPaymentFlag = process.env.GATSBY_ADD_SOM_PAYMENT === "true";
      const joinRobokassaPaymentFlag = process.env.GATSBY_ADD_ROBOKASSA_PAYMENT === "true";
      const joinRobokassaSbpPaymentFlag = process.env.GATSBY_ADD_ROBOKASSA_SBP_PAYMENT === "true";

      const userCards: DropdownValue[] = data.getUserCards?.map((c) =>
        cardToJsx(c)) ?? [];

      userCards.push(CLOUD_PAYMENT_CARD_VALUE);

      if (joinRobokassaSbpPaymentFlag) {
        userCards.push(ROBOKASSA_SBP_PAYMENT_CARD_VALUE);
      }

      userCards.push(CLOUD_PAYMENT_FOREIGN_CARD_VALUE);

      if (joinRobokassaPaymentFlag) {
        userCards.push(ROBOKASSA_PAYMENT_CARD_VALUE);
      }

      if (joinSomPaymentFlag) {
        userCards.push(SOM_PAYMENT_CARD_VALUE);
      }

      if (joinSoftLinePaymentFlag) {
        userCards.push(SOFTLINE_PAYMENT_CARD_VALUE);
      }

      setCards(userCards);
      const defaultCard = data.getUserCards.find((card) =>
        card.isDefault);

      if (defaultCard) {
        setSelectedCard(cardToJsx(defaultCard));
      } else {
        setSelectedCard(CLOUD_PAYMENT_CARD_VALUE);
      }
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (isSelectedSomPayment || isSelectedSoftlinePayment || isSelectedRobokassaPayemnt) {
      const paymentProviderType = isSelectedSomPayment
        ? PaymentProvider.SomPayments
        : PaymentProvider.SoftlinePayments;

      getExchangeRateQuery({
        variables: {
          input: {
            curFrom: isSelectedSomPayment ? Currency.USD : Currency.EUR,
            curTo: Currency.RUB,
            paymentProvider: paymentProviderType,
          },
        },
      });
    }
  }, [getExchangeRateQuery, isSelectedRobokassaPayemnt, isSelectedSoftlinePayment, isSelectedSomPayment, selectedCard]);

  const handlePaymentClick = () => {
    const amountForPayment = isPriceFixed || selectedAmount;
    switch (true) {
      case isSelectedCloudPayemnt:
        openPaymentForm(
          amountForPayment,
          rememberCard,
          PaymentProvider.CloudPayments,
          "",
          isPriceFixed ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedCloudForeignPayemnt:
        openPaymentForm(
          amountForPayment,
          rememberCard,
          PaymentProvider.CloudPayments,
          "",
          isPriceFixed ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedSomPayment:
        setLoadingForForeignPayment(true);
        openPaymentForm(
          amountForPayment,
          rememberCard,
          PaymentProvider.SomPayments,
          redirectPathAfterPayment,
          isPriceFixed ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedSoftlinePayment:
        setLoadingForForeignPayment(true);
        openPaymentForm(
          amountForPayment,
          rememberCard,
          PaymentProvider.SoftlinePayments,
          redirectPathAfterPayment,
          isPriceFixed ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedRobokassaPayemnt:
        setLoadingForForeignPayment(true);
        openPaymentForm(
          amountForPayment,
          false, // На первом этапе разработки карту не сохраняем!
          PaymentProvider.RobokassaPayments,
          redirectPathAfterPayment,
          isPriceFixed ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedSBPPayemnt:
        setLoadingForForeignPayment(true);
        openPaymentForm(
          amountForPayment,
          false, // На первом этапе разработки карту не сохраняем!
          PaymentProvider.Other,
          redirectPathAfterPayment,
          isPriceFixed ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      default:
        payWithSavedCard(
          amountForPayment,
          Number(selectedCard.value),
          isPriceFixed ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseInt(e.target.value, 10);
    // user may enter zeros before the value, and we preserve them,
    // but we make sure the value is valid
    setSelectedAmount((oldValue) =>
      (numberValue.toString().length <= MAX_DIGITS_AMOUNT ? numberValue : oldValue));
  };

  const handleCardChange = (value: DropdownValue) => {
    setSelectedCard(value);
  };

  const handleBulletClick = (amount: number) => {
    setSelectedAmount(amount);
  };

  const getValueAmountInput = () => {
    if (isPriceFixed) {
      return isPriceFixed ? isPriceFixed.toString() : "";
    }

    return Number.isNaN(selectedAmount) ? "" : selectedAmount;
  };

  const getSubtextForPaymentButton = (isFixPaymentAmount: number | null, amount: number) => {
    if (isFixPaymentAmount) {
      return `на ${priceToString(isFixPaymentAmount || 1000)} ₽`;
    }
    if (amount) {
      return `на ${priceToString(amount)} ₽`;
    }
    return "";
  };

  const calculatedBonus = calculateCashbackData?.calculateCashback?.bonus;
  const calculatedProfit = calculateCashbackData?.calculateCashback?.profit;
  const fixedPrice = getFixedPrice();
  const showCheckboxForSaveCard = isSelectedCloudPayemnt
  || isSelectedSomPayment
  || isSelectedSoftlinePayment
  || isSelectedRobokassaPayemnt
  || isSelectedCloudForeignPayemnt;

  return (
    <>
      <Helmet>
        <html className="html--full-height" lang="ru" />
        <body className="footer--short header--short" />
      </Helmet>
      <div className="profile-payment__container">
        <div className="profile-payment">
          <h2 className="profile-payment__header">Пополнение баланса</h2>
          {fixedPrice && <div className="profile-payment__subheader">для оплаты услуги на Lunaro</div>}
          {!fixedPrice && data?.getMyProfile && (
            <div className="profile-payment__card">
              <div>Ваш баланс</div>
              <h4 className="profile-payment__balance">
                <span
                  className="profile-payment__amount"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromTags(
                      priceToString(data.getMyProfile.balance.amount).replace(" ", "&nbsp;"),
                    ),
                  }}
                />
                &nbsp;
                <span className="profile-payment__currency">
                  {currencyToString(data.getMyProfile.balance.currency)}
                </span>
              </h4>
            </div>
          )}

          <form
            className="profile-payment__form"
            onSubmit={(e) =>
              e.preventDefault()}
          >
            <div className="profile-payment__field-group">
              <h4 className="profile-payment__label">
                <label htmlFor="amount">Сумма пополнения</label>
              </h4>
              <input
                id="amount"
                name="amount"
                type="number"
                pattern="\d*"
                inputMode="numeric"
                value={getValueAmountInput()}
                onChange={handleAmountChange}
                onBlur={() =>
                  setIsAmountTouched(true)}
                className={`profile-payment__input amount
                  ${isAmountTouched && !isAmountValid(selectedAmount) ? " error" : ""}
                  ${isPriceFixed ? "profile-payment__fix-payment-input" : ""}
                `}
                aria-invalid={isAmountTouched && !isAmountValid(selectedAmount) ? "true" : "false"}
              />
              {isAmountTouched && !isAmountValid(selectedAmount) && (
                <div className="auth__error" role="alert">
                  {`Минимальная сумма пополнения — ${MIN_PAYMENT_AMOUNT} ₽`}
                </div>
              )}
            </div>

            {!isPriceFixed && (
              <PaymentAmountPicker
                handleBulletClick={handleBulletClick}
                dataFormButtons={dataFormButtons?.getPaymentFormData}
              />
            )}

            <div className="profile-payment__field-group">
              <h4
                className={`profile-payment__label ${
                  hasCashBackButtons ? "profile-payment__label-for-cashback" : ""
                }`}
              >
                <label htmlFor="card">Способ пополнения</label>
              </h4>
              <Dropdown
                id="card"
                name="card"
                value={selectedCard}
                options={cards}
                onChange={handleCardChange}
                size={DropdownSizeEnum.Middle}
                className="profile-payment__input"
              />
              {(isSelectedSomPayment || isSelectedSoftlinePayment)
                && dataExchangeRate
                && selectedAmount && (
                  <ExchangeRate
                    amount={selectedAmount}
                    rate={dataExchangeRate.getExchangeRate}
                    paymentUSD={isSelectedSomPayment}
                  />
              )}
            </div>

            <div className="profile-payment__payment-button-container">
              {showCashBackDetailsCard && (
                <CashbackDetailsCard onClick={toggleShowCashBackDetailsCard} />
              )}

              {!isPriceFixed && calculatedBonus && !showCashBackDetailsCard && (
                <>
                  <PaymentCashback
                    bonusAmount={calculatedBonus.bonusAmount}
                    bonusFormula={calculatedBonus.bonusFormula}
                    profitAmount={calculatedProfit?.amount}
                    profitType={calculatedProfit?.type}
                    toggleShowCashBackDetailsCard={toggleShowCashBackDetailsCard}
                  />
                </>
              )}

              {!showCashBackDetailsCard && (
                <Button
                  text="Пополнить"
                  subtext={getSubtextForPaymentButton(isPriceFixed, selectedAmount)}
                  size={ButtonSizeEnum.Large}
                  color={ButtonColorEnum.Dark}
                  className="profile-payment__submit"
                  isLoading={
                    payWithSavedCardLoading
                    || loadingForForeignPayment
                    || !!savedCardPaymentResultPath
                  }
                  disabled={!isAmountValid(isPriceFixed || selectedAmount)}
                  onClick={handlePaymentClick}
                />
              )}
            </div>

            {showCheckboxForSaveCard && (
            <div className="profile-payment__remember-block">
              {!isSelectedRobokassaPayemnt && (
                <>
                  <div className="profile-payment__checkbox-group">
                    <input
                      type="checkbox"
                      checked={rememberCard}
                      onChange={() =>
                        setRememberCard((prevVal) =>
                          !prevVal)}
                    />
                    <span className="profile-payment__checkbox-label">
                      {paymentTexts.rememberText}
                    </span>
                  </div>
                  <div className="profile-payment__message">{paymentTexts.message}</div>
                </>
              )}
              <div className="profile-payment__message">
                <Icon type={IconTypeEnum.Shield} size={IconSizeEnum.Size28} />
                {paymentTexts.protectionInfo}
              </div>
            </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
